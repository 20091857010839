import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Header from "../components/header/header"
import Content from "../components/content/content"

import { Loading } from "../components/loading"
import { Form, Input, FormGroup, Button, Label, Col, Row } from "reactstrap"
import { fetch_post } from "../utils/fetch"
import { toast } from "react-toastify"
import { useAuth0 } from "@auth0/auth0-react"
import { checkPassword } from "../utils/common"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const { isLoading, isAuthenticated, user } = useAuth0()
  const [firstName, setFirstName] = useState("")
  const [surname, setSurname] = useState("")
  const [email, setEmail] = useState("")
  const [organisation, setOrganisation] = useState("")
  const [inputCode, setInputCode] = useState("")
  const [markerCode, setMarkerCode] = useState("")
  const [loading, setLoading] = useState(false)
  const [formInvalid, setFormInvalid] = useState(true)
  const [inputCodeInvalid, setInputCodeInvalid] = useState("")
  const [showModal, setShowModal] = useState(false)

    //Verification Code
    const createCode = () => {
      var canvasBtn = document.getElementById("mycanvas")
      var context = canvasBtn.getContext("2d")
      var codeNum = ""
      var codeLength = 4
      var random = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ]
      canvasBtn.width = 70
      canvasBtn.height = 36
      for (var i = 0; i < codeLength; i++) {
        var index = Math.floor(Math.random() * 36)
        codeNum += random[index]
      }
      const result = codeNum.toLowerCase()
      setMarkerCode(result)
      context.font = "20px Lato"
      context.fillStyle = "#495057"
      context.textAlign = "center"
      context.fillText(result, 35, 25)

      //Draw interference lines
      for (var l = 0; l <= 5; l++) {
        context.strokeStyle = randomColor()
        context.beginPath()
        context.moveTo(Math.random() * 70, Math.random() * 36)
        context.lineTo(Math.random() * 70, Math.random() * 36)
        context.stroke()
      }

      //Draw interference points
      for (var p = 0; p <= 30; p++) {
        context.strokeStyle = randomColor()
        context.beginPath()
        var x = Math.random() * 70
        var y = Math.random() * 36
        context.moveTo(x, y)
        context.lineTo(x + 1, y + 1)
        context.stroke()
      }
    }

    // Random color
    const randomColor = () => {
      var r = Math.floor(Math.random() * 256)
      var g = Math.floor(Math.random() * 256)
      var b = Math.floor(Math.random() * 256)
      return "rgb(" + r + "," + g + "," + b + ")"
    }

    useEffect(() => {
      if (!isLoading) {
        if (markerCode === "") {
          createCode()
        }
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, markerCode])

    useEffect(() => {
      // Check whether it can be committed
      if (inputCode.length <= 0) {
        setInputCodeInvalid("success")
      } else if (String(inputCode) !== String(markerCode)) {
        setInputCodeInvalid("error")
      } else {
        setInputCodeInvalid("success")
      }

      if (
        String(inputCode) === String(markerCode) &&
        firstName !== "" &&
        surname !== "" &&
        email !== "" &&
        organisation !== ""
      ) {
        setFormInvalid(false)
      } else {
        setFormInvalid(true)
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstName, surname, email, organisation, inputCode, markerCode])

    if (isLoading) {
      return <Loading />
    }

    const handleSubmit = event => {
      event.preventDefault()
      if (checkPassword(isAuthenticated, user)) return
      setLoading(true)
      fetch_post(
        `ui/about/contact`,
        {
          firstName,
          surname,
          email,
          organisation,
        },
        null
      )
        .then(() => {
          setShowModal(true)
        })
        .catch(error => {
          toast(`${error}`, {
            type: "error",
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
    const handleCloseModal = () => {
      setShowModal(null)
      setFirstName("")
      setSurname("")
      setEmail("")
      setOrganisation("")
      setInputCode("")
      setMarkerCode("")
    }

  return (
    <Layout pageTitle={frontmatter.title}>
      <Header
        backgroundImage={frontmatter.image}
        contentPage={true}
        className="contentPage"
      >
        <h1 className="display-4 font-weight-bold">{frontmatter.title}</h1>
      </Header>
      <>
        <Content>
          <div style={{fontSize: '18px'}}dangerouslySetInnerHTML={{ __html: html }} />
        </Content>
        <div style={{background: '#E6E6E6'}}>
          <Content>
            <div style={{display: 'flex', padding: '20px 0px', alignItems: 'center', justifyContent: 'space-between'}}>
              <div style={{width: '58%'}}>
                <div style={{fontWeight: 700, fontSize: 22}}>
                  A seamlessly integrated world class software<br/>distribution platform provided by Linaro
                </div>
                <br/>
                <p style={{marginBottom: '0.5rem'}}>
                  CodeLinaro integrates world leading functional components for software distribution into a single consistent view.
                </p>
                <p>
                  Through CodeLinaro’s centralized user management, you can provision and manage user and customer access to your software products and projects from one single platform.
                </p>
                <br/>
              </div>
              <img src={'/CL_about_gr_1.svg'} height={250}></img>
            </div>
          </Content>
        </div>
        <Content>
          <div style={{display: 'flex', justifyContent: 'space-between', padding: '1rem 0px', textAlign: 'center'}}>
            <div style={{width: '30%'}}>
              <div style={{textAlign: 'center'}}><img src={'/CL_about_gr_2.svg'} style={{height: '100px', marginBottom: '0.5rem'}}></img></div>
              <div style={{fontWeight: 700, fontSize: 22}}>
                Designed to enable software distribution
              </div>
              <br/>
              <p style={{marginBottom: '0.5rem'}}>
                CodeLinaro enables you to distribute both open source and proprietary software components to your customers and communities.
              </p>
              <p>
                CodeLinaro supports publishing to a wide variety of repository types.
              </p>
            </div>
            <div style={{width: '30%'}}>
              <div style={{textAlign: 'center'}}><img src={'/CL_about_gr_3.svg'} style={{height: '100px', marginBottom: '0.5rem'}}></img></div>
              <div style={{fontWeight: 700, fontSize: 22}}>
                Securely manage access to your software
              </div>
              <br/>
              <p>
                CodeLinaro is a business friendly solution that allows companies to decide what to share, when to share it and with whom and securely manage the process from end-to-end.
              </p>
            </div>
            <div style={{width: '30%'}}>
              <div style={{textAlign: 'center'}}><img src={'/CL_about_gr_4.svg'} style={{height: '100px', marginBottom: '0.5rem'}}></img></div>
              <div style={{fontWeight: 700, fontSize: 22}}>
                Automate and build upon your existing business processes
              </div>
              <br/>
              <p>
                Through CodeLinaro’s APIs, you can integrate the platform with your internal business infrastructure, effectively streamlining processes by making CodeLinaro software distribution part of your company’s workflow.
              </p>
            </div>
          </div>
        </Content>
        <div style={{background: '#C2C2C2', padding: "20px 0px"}}>
          <Content>
            {showModal ? (
              <div style={{ fontSize: "16px" }}>
                <p>Thank you for reaching out!</p>
                <p>A member of the CodeLinaro team will get in touch with you soon.</p>
                {/* <Button className="btnBgColor" onClick={handleCloseModal}>
                  Back
                </Button> */}
              </div>
            ) : (
            <>
              <div style={{fontWeight: 700, fontSize: 22, marginBottom: 40}}>Complete the form to receive more information about Codelinaro</div>
              <Form>
                  <FormGroup row>
                    <Label for="firstName" style={{width: 120, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                      First name
                    </Label>
                    <Col sm={5}>
                      <Input
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={firstName}
                        onChange={event => setFirstName(event.target.value)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="surname" style={{width: 120, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                      Last name
                    </Label>
                    <Col sm={5}>
                      <Input
                        type="text"
                        name="surname"
                        id="surname"
                        value={surname}
                        onChange={event => setSurname(event.target.value)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="email" style={{width: 120, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                      Email
                    </Label>
                    <Col sm={5}>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="organisation" style={{width: 120, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                      Organization
                    </Label>
                    <Col sm={5}>
                      <Input
                        type="text"
                        name="organisation"
                        id="organisation"
                        value={organisation}
                        onChange={event => setOrganisation(event.target.value)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="captcha" style={{width: 120, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                      Captcha
                    </Label>
                    <Col sm={4}>
                      <Input
                        type="text"
                        name="inputCode"
                        id="inputCode"
                        value={inputCode}
                        onChange={event => setInputCode(event.target.value)}
                      />
                    </Col>
                    <Col sm={1}>
                      <div className="captchaBtn" style={{background: 'white'}}>
                        <canvas id="mycanvas" onClick={createCode}></canvas>
                      </div>
                    </Col>
                  </FormGroup>
                  <Row>
                    <Label for="captcha" style={{width: 120}}>
                    </Label>
                    <Col>
                      <p style={{ fontSize: 12, color: "red" }}>
                        {inputCodeInvalid === "success" ? "" : "Wrong input"}
                      </p>
                      <Button
                        className="mr-2 btnBgColor"
                        color={"primary"}
                        onClick={handleSubmit}
                        disabled={formInvalid}
                        style={{width: 150, display: 'flex', alignItems: 'center',justifyContent: 'center'}}
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          >
                            <span className="sr-only">Sending...</span>
                          </span>
                        ) : (<>
                          <img src="/favicon_white.svg" alt="favicon" style={{height: 20, marginRight: 8}}/>
                            Submit
                          </>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </>
            )}

          </Content>
        </div>
      </>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        image
      }
    }
  }
`
